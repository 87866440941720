<template>
  <div>
    <b-card class="mb-2">
      <h2>Ajouter un agent du MTCA</h2>
      <!-- <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text> -->
    </b-card>
    <b-card class="mb-0">
      <!-- <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text> -->
      <!-- form -->
      <b-form
        class="auth-register-form mt-2"
        @submit.prevent="submitForm"
      >
        <!-- nom -->
        <b-form-group
          label="Nom"
          label-for="nom"
        >
          <b-form-input
            id="nom"
            v-model="form.nom"
            :state="$v.form.nom.$error ? false : null"
            name="nom"
          />
          <div v-if="$v.form.nom.$error">
            <small
              v-if="$v.form.nom.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>

        <!-- prenom -->
        <b-form-group
          label="Prénom"
          label-for="prenom"
        >
          <b-form-input
            id="prenom"
            v-model="form.prenom"
            :state="$v.form.prenom.$error ? false : null"
            name="prenom"
          />
          <div v-if="$v.form.prenom.$error">
            <small
              v-if="$v.form.prenom.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>

        <!-- email -->
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            :state="$v.form.email.$error ? false : null"
            name="email"
          />
          <div v-if="$v.form.email.$error">
            <small
              v-if="$v.form.email.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
            <small
              v-if="$v.form.email.emailValidator.$invalid"
              class="text-danger"
            >Adresse email invalid</small>
          </div>
        </b-form-group>

        <!-- Poste au ministere -->
        <b-form-group
          label="Poste au ministère"
          label-for="poste"
        >
          <b-form-input
            id="poste"
            v-model="form.poste"
            :state="$v.form.poste.$error ? false : null"
            name="Poste"
          />
          <div v-if="$v.form.poste.$error">
            <small
              v-if="$v.form.poste.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>

        <!-- Piece jointe -->
        <!-- <b-form-group
          label="Pieces justificatives (Obligatoire) (5 fichiers au maximum)"
          label-for="pieces_jointe"
        >
          <b-form-file
            v-model="form.pieces_justificatives"
            multiple
            :state="$v.form.pieces_justificatives.$error ? false : null"
            placeholder="Choissisez un fichier ou glisser déposer ici..."
            drop-placeholder="Déposer ici..."
          />
          <div v-if="$v.form.pieces_justificatives.$error">
            <small
              v-if="$v.form.pieces_justificatives.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group> -->

        <div class="mb-1" v-if="false">
          <!-- Styled -->
          <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
          <small
            v-if="$v.recaptchaResponse.isValid.$invalid"
            class="text-danger"
          >Veuillez résoudre le recaptcha pour continuer</small>
        </div>

        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
          @click="submitForm"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          S'inscrire
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email as emailValidator } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'

import rolesStoreModule from "@/store/roles";
import agentGouvenementStoreModule from "@/store/agent-gouvenement";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "roles", module: rolesStoreModule },
      { path: "agentGouvenement", module: agentGouvenementStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    ToastificationContent,
  },
  data() {
    return {
      form: {
        first: '',
        lastname: '',
        poste: '',
        email: '',
        // pieces_justificatives: [],
        role: '',
        // /roles/lists code==
      },
      currentUserId: localstorageService.getUserId(),
      currentRole: null,

      // recaptchaResponse: null,
      passwordFieldType: 'password',
      isLoading: false,
      listEtablissements: [],
    }
  },
  computed: {},
  watch: {},

  validations() {
    return {
      form: {
        nom: { required },
        prenom: { required },
        email: { required, emailValidator },
        poste: { required },
        // pieces_justificatives: { required },
      },

      // recaptchaResponse: { isValid: val => val && val.type === 'success' },
      isLoading: false,
    }
  },
  methods: {
    ...mapActions('agentGouvenement', {
      createAgentGouvenement: 'createAgentGouvenement',
      findAgentGouvenement: 'findAgentGouvenement',
    }),
    ...mapActions('roles', {
      fetchRoles: 'fetchRoles',
    }),
    ...mapActions('imagePuzzleSliderCaptcha', {showSliderCaptcha:"show" }),
    // ----------------------------------------
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    async submitForm() {
      if (this.isLoading) return
      
      // const isFormCorrect = await this.$v.$validate()
      // if (!isFormCorrect) return
      this.isLoading = true;

      
      // const sliderCaptchaResponse = await this.showSliderCaptcha()
      // const {isSolved} = sliderCaptchaResponse || {}
      // if(!isSolved){
      //   this.isLoading = false
      //   return
      // }

      // Execute reCAPTCHA with action "submit".
      const token = await this.$recaptcha('submit')
      if (!token) {
        this.isLoading = false
        return
      }
      // console.log('token: ', token);
      
      
      const data = {
        firstname: this.form.nom,
        lastname: this.form.prenom,
        email: this.form.email,
        poste: this.form.poste,
        role: this.form.role,
        token,
      }
      this.createAgentGouvenement(data)
        .then(result => {
          this.isLoading = false
          this.$router.push({ name: 'agents-listes-des-agents' })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Inscription réussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getRoleAgentMTCA() {
      const data = { options: { code: 'agent_mtca' } }
      this.fetchRoles(data)
        .then(res => {
          if (res.data && res.data.resources[0]) {
            this.form.role = res.data.resources[0]._id
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  mounted() {
    this.getRoleAgentMTCA()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
